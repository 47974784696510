import React from "react";
import styled from "styled-components";
import { Text } from "../../../../Components/Components";

const Parents = styled.div`
  width: 90%;
`;
const TopContents = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: ${(e) => e.margin || "0"};
`;

const MobileTopContents = () => {
  return (
    <Parents>
      <TopContents>
        <Text color="#2F80ED" bold="bold" size="1.3em" margin="1em 0 0 0">
          CONTACT US
        </Text>
        <Text margin="1em 0 0 0" size="1.2em" bold="bold">
          문의하기
        </Text>
        <Text
          margin="0.5em 0 0 0"
          color="#2F80ED"
          size="1.1em"
          bold="bold"
          lineHeight="1.3em"
        >
          medieng@medieng.com
          <br />
          063) 231-5004
        </Text>

        <Text margin="1em 0 0 0" size="1.2em" bold="bold">
          찾아오시는 길
        </Text>
        <Text margin="0.5em 0 0 0" size="1.1em" lineHeight="1.3em">
          (55011) 전라북도 전주시 완산구 아중로 33 (중노송동)
          <br />
          전주정보문화산업진흥원 문화산업지원센터 205호
        </Text>
      </TopContents>
    </Parents>
  );
};
export default MobileTopContents;
