export const data = [
  {
    image: require("../../image/iot.svg").default,
    title: "IoT 서비스",
    mobiletitle: "IoT",
    contents: [
      {
        text: "센서 기반 정보 감지와 서버를 통한 데이터 수집",
      },
      {
        text: "다양한 현장에서 운영중인 여러 IoT 장치들의 상태를 모니터링",
      },
      {
        text: "DC/AC 배터리, 전원 관리 회로 모듈 설계 및 제작",
      },
    ],
  },
  {
    image: require("../../image/sw.svg").default,
    title: "S/W Development",
    contents: [
      {
        text: "UX 전문가를 통한 사용자를 위한 최상의 서비스",
      },
      {
        text: "안드로이드, 아이폰, PC 등 여러 기종에 구애받지 않는 서비스 제공",
      },
    ],
  },
  {
    image: require("../../image/cloud.svg").default,
    title: "클라우드 서비스",
    mobiletitle: "Cloud, Server",
    contents: [
      {
        text: "상용 클라우드 가상 서버(AWS, MS Azure, NAVER Cloud 등) 또는 기존 IDC 서버 기반 서비스 설계 및 운영",
      },
      {
        text: "PasS, SaaS 시스템 설계 및 운영",
      },
    ],
  },
  {
    image: require("../../image/bigdata.svg").default,
    title: "BigData, AI",
    contents: [
      {
        text: "정형, 비정형 센서 정보 수집을 위한 빅데이터 시스템 설계 및 운영",
      },
      {
        text: "Cassandra, Spark 등을 각종 도구를 활용한 빅데이터 환경 설정",
      },
      {
        text: "TensorFlow, YOLO, PyTorch 등 도구를 이용한 영상 처리, 머신 러닝 등 AI 기반 서비스 설계 및 운영",
      },
    ],
  },
];
