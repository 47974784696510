import React from "react";
import styled, { css } from "styled-components";
import { Text } from "../../../../Components/Components";
import { images } from "../../Images";
import { useGetMobile, check_Tablet } from "../../../../Util/Util";

const Parents = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 80vh;
  height: calc(var(--vh, 1vh) * 80);
  ${check_Tablet() &&
  css`
    height: calc(var(--vh, 1vh) * 80);
  `}
  ${(e) =>
    e.mobile &&
    css`
      font-size: 20px;
      height: calc(var(--vh, 1vh) * 87);
    `}
  background: #f9fafb;
`;
const Imges = styled.div`
  width: ${(e) => e.mobile};
  height: 50%;
  background: url(${images.foodplan}) no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
`;
const TextAlign = styled.div`
  width: 40%;
  ${(e) =>
    e.mobile
      ? css`
          width: 87%;
        `
      : css`
          @media screen and (max-width: 1466px) {
            width: 50%;
          }
        `}
  text-align: left;
  display: flex;
  flex-direction: column;
`;
const IntroFoodPlan = () => {
  return (
    <Parents mobile={useGetMobile()} id="foodPlan">
      <Text
        size={useGetMobile() ? "1.7em" : "2em"}
        color="#374252"
        lineHeight={useGetMobile() ? "1.3em" : ""}
        bold="bold"
        margin={useGetMobile() && "5% 0 0 0"}
      >
        로컬푸드의 도약,{useGetMobile() ? <br /> : <>&nbsp;&nbsp;</>}
        이젠 푸드플랜!
      </Text>
      <Imges mobile={useGetMobile() ? "100%" : "35%"} />
      <TextAlign mobile={useGetMobile()}>
        <Text
          size={useGetMobile() ? "0.9em" : "1.1em"}
          lineHeight={useGetMobile() ? "1.3em" : "30px"}
        >
          로컬푸드와 함께 성장한{" "}
          <Text bold="bold" size="1em">
            메디앙시스템
          </Text>
          에서 직매장과 급식센터를 통합하여 기획생산 할 수 있는
          <Text color="#2F80ED" bold="bold" size="1em">
            &nbsp;푸드플랜 통합 솔루션
          </Text>
          을 통해 지역 단체들의 푸드플랜 구축을 지원합니다.
        </Text>
      </TextAlign>
    </Parents>
  );
};
export default IntroFoodPlan;
