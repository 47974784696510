import React from "react";
import { Text } from "../../../../../Components/Components";
import {
  MainContents,
  AlignContents,
  LeftContents,
  ListParents,
  RightContents,
  Image,
  TextGroup,
} from "../Slide";

function One({ select }) {
  return (
    <MainContents move={`translateX(${-100 * select}%)`}>
      <AlignContents>
        <LeftContents>
          <Text color="#374252" bold="bold" size="1em" margin="0 0 0.8em 0">
            로컬푸드 10년의 노하우를 결집한
          </Text>
          <Text color="#374252" bold="bold" size="1.84em" margin="0 0 25px 0">
            직매장 운영 시스템
          </Text>
          <ListParents>
            <TextGroup>
              <Text
                color="#374252"
                size="0.9em"
                margin="10px 0"
                lineHeight="1.2em"
              >
                -&nbsp;
              </Text>
              <Text
                color="#374252"
                size="0.9em"
                margin="10px 0"
                lineHeight="1.2em"
              >
                농가와 상품 관리, 판매, 분석, 정산 등&nbsp;
                <Text size="1em" bold="bold" color="#374252">
                  로컬푸드에 특화된 매장 관리 기능
                </Text>
              </Text>
            </TextGroup>
            <TextGroup>
              <Text
                color="#374252"
                size="0.9em"
                margin="10px 0"
                lineHeight="1.2em"
              >
                -&nbsp;
              </Text>
              <Text
                color="#374252"
                size="0.9em"
                margin="10px 0"
                lineHeight="1.2em"
              >
                신뢰할 수 있는 먹거리, 안심할수 있는 먹거리를 위한&nbsp;
                <Text size="1em" bold="bold" color="#374252">
                  교육 및 인증 관리 기능
                </Text>
              </Text>
            </TextGroup>
            <TextGroup>
              <Text
                color="#374252"
                size="0.9em"
                margin="10px 0"
                lineHeight="1.2em"
              >
                -&nbsp;
              </Text>

              <Text
                color="#374252"
                size="0.9em"
                margin="10px 0"
                lineHeight="1.2em"
              >
                <Text size="1em" bold="bold" color="#374252">
                  재고관리, 이벤트 기능&nbsp;
                </Text>
                등 로컬푸드 직매장의 원할한 운영을 돕는 부가 기능들
              </Text>
            </TextGroup>
          </ListParents>
        </LeftContents>
        <RightContents>
          <Image src={require("../../../image/directstore.jpg")} />
        </RightContents>
      </AlignContents>
    </MainContents>
  );
}

function Two({ select }) {
  return (
    <MainContents move={`translateX(${-100 * select}%)`}>
      <AlignContents>
        <LeftContents>
          <Text color="#374252" bold="bold" size="1em" margin="0 0 10px 0">
            국내 유일 로컬푸드 기반
          </Text>
          <Text color="#374252" bold="bold" size="1.84em" margin="0 0 25px 0">
            급식센터 운영 시스템
          </Text>
          <ListParents>
            <TextGroup>
              <Text
                color="#374252"
                size="0.9em"
                margin="10px 0"
                lineHeight="1.2em"
              >
                -&nbsp;
              </Text>

              <Text
                color="#374252"
                size="0.9em"
                margin="10px 0"
                lineHeight="1.2em"
              >
                발주나 클레임을 등록 ,관리하는&nbsp;
                <Text size="1em" bold="bold" color="#374252">
                  온라인 주문 시스템
                </Text>
              </Text>
            </TextGroup>
            <TextGroup>
              <Text
                color="#374252"
                size="0.9em"
                margin="10px 0"
                lineHeight="1.2em"
              >
                -&nbsp;
              </Text>

              <Text
                color="#374252"
                size="0.9em"
                margin="10px 0"
                lineHeight="1.2em"
              >
                유통 단계의 변화에도 유연하게, 간편하게 대응할 수 있는&nbsp;
                <Text size="1em" bold="bold" color="#374252">
                  수주 위임 기능
                </Text>
              </Text>
            </TextGroup>
            <TextGroup>
              <Text
                color="#374252"
                size="0.9em"
                margin="10px 0"
                lineHeight="1.2em"
              >
                -&nbsp;
              </Text>
              <Text
                color="#374252"
                size="0.9em"
                margin="10px 0"
                lineHeight="1.2em"
              >
                학교급식과 공공급식부터 도농상생 급식까지&nbsp;
                <Text size="1em" bold="bold" color="#374252">
                  모든 급식에서 운영 가능
                </Text>
              </Text>
            </TextGroup>
            <TextGroup>
              <Text
                color="#374252"
                size="0.9em"
                margin="10px 0"
                lineHeight="1.2em"
              >
                -&nbsp;
              </Text>
              <Text
                color="#374252"
                size="0.9em"
                margin="10px 0"
                lineHeight="1.2em"
              >
                농가 · 상품 관리에서 발주, 수주까지,&nbsp;
                <Text size="1em" bold="bold" color="#374252">
                  간편하게 개선한 수발주 관리 기능
                </Text>
              </Text>
            </TextGroup>
          </ListParents>
        </LeftContents>
        <RightContents>
          <Image src={require("../../../image/cateringcenter.png")} />
        </RightContents>
      </AlignContents>
    </MainContents>
  );
}

function Three({ select }) {
  return (
    <MainContents move={`translateX(${-100 * select}%)`}>
      <AlignContents>
        <LeftContents>
          <Text color="#374252" bold="bold" size="1em" margin="0 0 10px 0">
            성공적인 푸드플랜을 위한 통합데이터 기반
          </Text>
          <Text color="#374252" bold="bold" size="1.84em" margin="0 0 25px 0">
            기획생산 시스템
          </Text>
          <ListParents>
            <TextGroup>
              <Text
                color="#374252"
                size="0.9em"
                margin="10px 0"
                lineHeight="1.2em"
              >
                -&nbsp;
              </Text>

              <Text
                color="#374252"
                size="0.9em"
                margin="10px 0"
                lineHeight="1.2em"
              >
                직매장 및 급식센터의 정보를 &nbsp;
                <Text size="1em" bold="bold" color="#374252">
                  하나로 통합
                </Text>
                하여 소비 분석할 수 있는&nbsp;
                <Text size="1em" bold="bold" color="#374252">
                  정보 분석도구
                </Text>
              </Text>
            </TextGroup>
            <TextGroup>
              <Text
                color="#374252"
                size="0.9em"
                margin="10px 0"
                lineHeight="1.2em"
              >
                -&nbsp;
              </Text>

              <Text
                color="#374252"
                size="0.9em"
                margin="10px 0"
                lineHeight="1.2em"
              >
                경작 현황에서&nbsp;
                <Text size="1em" bold="bold" color="#374252">
                  생산량 · 출하량까지&nbsp;
                </Text>
                한 흐름으로 관리하는&nbsp;
                <Text size="1em" bold="bold" color="#374252">
                  기획 생산 기능
                </Text>
              </Text>
            </TextGroup>
            <TextGroup>
              <Text
                color="#374252"
                size="0.9em"
                margin="10px 0"
                lineHeight="1.2em"
              >
                -&nbsp;
              </Text>
              <Text
                color="#374252"
                size="0.9em"
                margin="10px 0"
                lineHeight="1.2em"
              >
                국내 최다 농산물 유통 채널&nbsp;
                <Text size="1em" bold="bold" color="#374252">
                  “농협”과 연계&nbsp;
                </Text>
                <Text size="0.9em" color="gray">
                  (지원 예정)
                </Text>
              </Text>
            </TextGroup>
            <TextGroup>
              <Text
                color="#374252"
                size="0.9em"
                margin="10px 0"
                lineHeight="1.2em"
              >
                -&nbsp;
              </Text>
              <Text
                color="#374252"
                size="0.9em"
                margin="10px 0"
                lineHeight="1.2em"
              >
                여러 지역의 푸드플랜들이 하나로 연결되어 확장하는,&nbsp;
                <Text size="1em" bold="bold" color="#374252">
                  광역 푸드플랜&nbsp;
                </Text>
                <Text size="0.9em" color="gray">
                  (지원 예정)
                </Text>
              </Text>
            </TextGroup>
          </ListParents>
        </LeftContents>
        <RightContents>
          <Image src={require("../../../image/plannedproduction.png")} />
        </RightContents>
      </AlignContents>
    </MainContents>
  );
}

function Four({ select }) {
  return (
    <MainContents move={`translateX(${-100 * select}%)`}>
      <AlignContents>
        <LeftContents>
          <Text color="#374252" bold="bold" size="1em" margin="0 0 10px 0">
            복잡한 운영 자료들도 클릭만으로 만들어내는
          </Text>
          <Text color="#374252" bold="bold" size="1.84em" margin="0 0 25px 0">
            분석 & 보고 서비스
          </Text>
          <ListParents>
            <TextGroup>
              <Text
                color="#374252"
                size="0.9em"
                margin="10px 0"
                lineHeight="1.2em"
              >
                -&nbsp;
              </Text>

              <Text
                color="#374252"
                size="0.9em"
                margin="10px 0"
                lineHeight="1.2em"
              >
                서식을 원하는 형태의 서식을&nbsp;
                <Text size="1em" bold="bold" color="#374252">
                  구성
                </Text>
                하고 필요한 정보만&nbsp;
                <Text size="1em" bold="bold" color="#374252">
                  추출
                </Text>
                하는&nbsp;
                <Text size="1em" bold="bold" color="#374252">
                  정보 분석 기능
                </Text>
              </Text>
            </TextGroup>
            <TextGroup>
              <Text
                color="#374252"
                size="0.9em"
                margin="10px 0"
                lineHeight="1.2em"
              >
                -&nbsp;
              </Text>

              <Text
                color="#374252"
                size="0.9em"
                margin="10px 0"
                lineHeight="1.2em"
              >
                <Text size="1em" bold="bold" color="#374252">
                  클릭 한번
                </Text>
                으로 미리 만든 서식을 적용하고 그 서식 그대로&nbsp;
                <Text size="1em" bold="bold" color="#374252">
                  공유
                </Text>
                할 수 있는&nbsp;
                <Text size="1em" bold="bold" color="#374252">
                  서식 관리 기능
                </Text>
              </Text>
            </TextGroup>
            <TextGroup>
              <Text
                color="#374252"
                size="0.9em"
                margin="10px 0"
                lineHeight="1.2em"
              >
                -&nbsp;
              </Text>
              <Text
                color="#374252"
                size="0.9em"
                margin="10px 0"
                lineHeight="1.2em"
              >
                보이는 화면 그대로 엑셀로 보는&nbsp;
                <Text size="1em" bold="bold" color="#374252">
                  외부 공유 기능
                </Text>
              </Text>
            </TextGroup>
            <TextGroup>
              <Text
                color="#374252"
                size="0.9em"
                margin="10px 0"
                lineHeight="1.2em"
              >
                -&nbsp;
              </Text>
              <Text
                color="#374252"
                size="0.9em"
                margin="10px 0"
                lineHeight="1.2em"
              >
                도표와 그래프로 편하게 보는&nbsp;
                <Text size="1em" bold="bold" color="#374252">
                  시각화 기능&nbsp;
                </Text>
                <Text size="0.9em" color="gray">
                  (지원 예정)
                </Text>
              </Text>
            </TextGroup>
          </ListParents>
        </LeftContents>
        <RightContents>
          <Image src={require("../../../image/dataanalysis.png")} />
        </RightContents>
      </AlignContents>
    </MainContents>
  );
}

function Five({ select }) {
  return (
    <MainContents move={`translateX(${-100 * select}%)`}>
      <AlignContents>
        <LeftContents>
          <Text color="#374252" bold="bold" size="1em" margin="0 0 10px 0">
            언제든지, 어디서든지
          </Text>
          <Text color="#374252" bold="bold" size="1.84em" margin="0 0 25px 0">
            모바일 서비스
          </Text>
          <ListParents>
            <TextGroup>
              <Text
                color="#374252"
                size="0.9em"
                margin="10px 0"
                lineHeight="1.2em"
              >
                -&nbsp;
              </Text>

              <Text
                color="#374252"
                size="0.9em"
                margin="10px 0"
                lineHeight="1.2em"
              >
                외부에서도 업무를 진행할 수 있는&nbsp;
                <Text size="1em" bold="bold" color="#374252">
                  매니저용 APP
                </Text>
              </Text>
            </TextGroup>
            <TextGroup>
              <Text
                color="#374252"
                size="0.9em"
                margin="10px 0"
                lineHeight="1.2em"
              >
                -&nbsp;
              </Text>

              <Text
                color="#374252"
                size="0.9em"
                margin="10px 0"
                lineHeight="1.2em"
              >
                판매 & 수주 정보를 실시간 조회하는&nbsp;
                <Text size="1em" bold="bold" color="#374252">
                  농가 APP
                </Text>
              </Text>
            </TextGroup>
            <TextGroup>
              <Text
                color="#374252"
                size="0.9em"
                margin="10px 0"
                lineHeight="1.2em"
              >
                -&nbsp;
              </Text>
              <Text
                color="#374252"
                size="0.9em"
                margin="10px 0"
                lineHeight="1.2em"
              >
                로컬푸드 활용을 돕는&nbsp;
                <Text size="1em" bold="bold" color="#374252">
                  소비자용 APP&nbsp;
                </Text>
                <Text size="0.9em" color="gray">
                  (지원 예정)
                </Text>
              </Text>
            </TextGroup>
          </ListParents>
        </LeftContents>
        <RightContents>
          <Image src={require("../../../image/mobileservice.png")} />
        </RightContents>
      </AlignContents>
    </MainContents>
  );
}

export default function SlideItem({ select }) {
  return (
    <>
      <One select={select} />
      <Two select={select} />
      <Three select={select} />
      <Four select={select} />
      <Five select={select} />
    </>
  );
}
