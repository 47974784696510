import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import Icon from "./util/Icon";
import { useState } from "react";

const Parents = styled.div`
  width: 100%;
  position: fixed;
  top: 0;
  background: white;
  overflow: hidden;
  height: 86px;
  @media screen and (max-width: 930px) {
    transition: all 1s ease;
    height: ${(e) => (e.menuHeight ? "250px" : "60px")};
  }
  box-shadow: 0.5px 0.5px 2px gray;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 0 5%;
  @media screen and (max-width: 930px) {
    flex-direction: column;
    padding: 0 3%;
    justify-content: unset;
  }
`;

const LinkContents = styled.div`
  display: flex;
  align-items: center;
  @media screen and (max-width: 930px) {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
  }
`;

const LinkGroup = styled.div`
  display: flex;
  &:hover {
    & > div:nth-last-child(1) {
      width: 100%;
    }
  }
  flex-direction: column;
  margin: 0 15px;
  @media screen and (max-width: 930px) {
    padding: 10px 0;
  }
`;

const Text = styled.span`
  color: ${(e) => e.color || "black"};
  font-size: ${(e) => e.size || "0.9em"};
  font-family: "NotoSans";
  font-weight: ${(e) => e.bold || "400"};
  cursor: pointer;
`;

const LinkLine = styled.div`
  transition: all 0.3s ease;
  width: ${(e) => (e.width ? "100%" : "0%")};
  height: 3px;
  background: rgba(0, 0, 0, 0.6);
`;

const Link = styled(NavLink)`
  text-decoration: none;
  @media screen and (max-width: 930px) {
    display: flex;
    justify-content: flex-start;
  }
`;

const MobileMenuContents = styled.div`
  @media screen and (min-width: 930px) {
    display: none;
  }
`;

const LinkAlign = styled.div`
  @media screen and (max-width: 930px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
`;

const linkData = [
  {
    title: "개인정보 처리방침",
    value: 0,
  },
  {
    title: "회원 약관",
    value: 1,
  },
  {
    title: "개인정보 수집 동의서",
    value: 2,
  },
  {
    title: "회원 탈퇴 안내사항",
    value: 3,
  },
];

const Navbar = ({ value, setValue }) => {
  const [onMenu, setOnMenu] = useState(false);
  return (
    <Parents
      menuHeight={onMenu ? "true" : ""}
      onMouseLeave={() => setOnMenu(false)}
    >
      <Container>
        <LinkAlign>
          <Link to="/">
            <Icon.Logo style={{ cursor: "pointer", width: "6.5em" }} />
          </Link>
          <MobileMenuContents
            onClick={() => {
              setOnMenu(!onMenu);
            }}
          >
            <Icon.Menu
              style={{ cursor: "pointer", width: "25px", height: "25px" }}
            />
          </MobileMenuContents>
        </LinkAlign>
        <LinkContents>
          {linkData.map((r, index) => (
            <LinkGroup
              onClick={() => {
                setValue(r.value);
                window.scrollTo(0, 0);
              }}
            >
              <Text size="1em" bold="bold">
                {r.title}
              </Text>
              <LinkLine width={value == r.value ? "true" : ""} />
            </LinkGroup>
          ))}
        </LinkContents>
      </Container>
    </Parents>
  );
};
export default Navbar;
