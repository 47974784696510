export const data = [
  {
    id: "introduce",
    name: "비전",
  },
  {
    id: "foodPlan",
    name: "푸드플랜",
  },
  {
    id: "technology",
    name: "보유기술",
  },
  {
    id: "ourCuture",
    name: "팀문화",
  },
  {
    id: "footered",
    name: "연락처",
  },
];
export const moblieData = [
  {
    id: "introduce",
    name: "회사비전",
  },
  {
    id: "foodPlan",
    name: "푸드플랜이란",
  },
  {
    id: "mobiletechnology",
    name: "보유기술",
  },
  {
    id: "mobileOurCuture",
    name: "팀문화",
  },
  {
    id: "footered",
    name: "연락하기",
  },
];
