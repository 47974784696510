import React, { useEffect } from "react";
import styled, { css } from "styled-components";
import { KaKaoMap, IEKaKaoMap } from "./KaKaoMap";
import TopContent from "./TopContent";
import Footer from "./Footer";
import MobileTopContents from "./MobileTopContents";
import { useGetMobile, is_Browser } from "../../../../Util/Util";

const Parents = styled.div`
  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 91);
  ${(e) =>
    e.mobile &&
    css`
      height: calc(var(--vh, 1vh) * 91);
    `}
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fbfbfb;
`;
const Contents = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

const BottomInfo = () => {
  const name = "메디앙시스템";
  return (
    <Parents mobile={useGetMobile()} id="footered">
      <Contents>
        {useGetMobile() ? <MobileTopContents /> : <TopContent />}
        {is_Browser() ? KaKaoMap({ name: name }) : IEKaKaoMap({ name: name })}
        <Footer />
      </Contents>
    </Parents>
  );
};
export default BottomInfo;
