import React, { useEffect } from "react";
import styled from "styled-components";
import TopBar from "./TopBar/TopBar";
import Contents from "./Contents/Contents";
import MobileTopBar from "./TopBar/MobileTopBar";
import { is_Mobile, useGetMobile } from "../../Util/Util";
import { useSetRecoilState } from "recoil";
import { mobiles } from "../../Recoil/recoil";
import Grid from "./Grid";

const Parents = styled.div`
  position: relative;
  width: 100%;
`;

const Main = () => {
  const setMobile = useSetRecoilState(mobiles);
  useEffect(() => {
    resetSize();
  }, []);
  window.addEventListener("resize", () => {
    setMobile(is_Mobile() ? true : false);
  });
  const resetSize = () => {
    setMobile(is_Mobile() ? true : false);
    let vh = window.innerHeight * 0.01;
    let vw = window.innerWidth * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  };

  return (
    <Parents>
      {useGetMobile() ? <MobileTopBar /> : <TopBar />}
      {/* <Grid /> */}
      <Contents />
    </Parents>
  );
};
export default Main;
