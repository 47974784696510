import React from "react";
import styled, { css, keyframes } from "styled-components";
import { Text } from "../../../Components/Components";
import { images } from "../Images";
import {
  is_Browser,
  is_Mobile,
  useGetMobile,
  check_Tablet,
} from "../../../Util/Util";
import { animateScroll as scroll } from "react-scroll";

const Parents = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
`;
const BackgroundImage = styled.div`
  width: 100%;
  height: 100%;
  background: url(${images.topimage}) no-repeat;
  background-size: cover;
  ${(e) =>
    e.mobile &&
    css`
      background-size: 100% calc(var(--vh, 1vh) * 100);
      background-size: cover;
    `}
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  user-select: none;
`;
const ArrowAnimation = keyframes`
  0%{
    bottom: 5%;
    opacity: 0.2;
  }
  50%{
    bottom: 3%;
    opacity: 1;
  }
  100%{
    bottom: 5%;
    opacity: 0.2;
  }
`;
const MobileAnimation = keyframes`
  0%{
    bottom: 3%;
    opacity: 0.2;
  }
  50%{
    bottom: 7%;
    opacity: 1;
  }
  100%{
    bottom: 3%;
    opacity: 0.2;
  }
`;
const MobileArrowAnimation = keyframes`
  0%{
    bottom: 8%;
    opacity: 0.2;
  }
  50%{
    bottom: 11%;
    opacity: 1;
  }
  100%{
    bottom: 8%;
    opacity: 0.2;
  }
`;
const Video = styled.video`
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  object-fit: cover;
  -o-object-fit: cover;
  z-index: 1;
`;

const Img = styled.img`
  width: ${(e) => (e.mobile ? "40px" : "69px")};
  position: absolute;
  height: 40px;
  animation: ${(e) => (e.bottom ? "unset" : ArrowAnimation)} 2.5s infinite ease;
  bottom: ${(e) => e.bottom || "5%"};
  cursor: pointer;
`;
const MobileImage = styled.img`
  animation: ${MobileAnimation} 2.5s infinite ease;
  position: absolute;
  height: 40px;
  bottom: ${(e) => e.bottom || "5%"};
  ${(e) =>
    e.bottom &&
    css`
      transform: rotate(180deg);
    `}
`;
const TextGroup = styled.div`
  position: absolute;
  z-index: 3;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  user-select: none;
`;

const TopContents = () => {
  const moveScroll = (d) => {
    let foodplanY = document.getElementById("foodPlan").offsetTop;
    let margin = 0;
    if (d) margin = document.getElementById("tabbar").offsetHeight * 0.5;
    scroll.scrollTo(foodplanY - margin);
  };
  return (
    <Parents mobile={useGetMobile()} id="introduce">
      <TextGroup>
        {useGetMobile() ? (
          <>
            <Text color="white" size="1.4em">
              차세대 기술과 끝없는 도전으로
            </Text>
            <Text color="white" padding="15px 0" size="1.4em">
              데이터 중심의 디지털 전환을 이끌어나갑니다.
            </Text>
          </>
        ) : (
          <>
            <Text color="white" size="2em">
              차세대 기술과 끝없는 도전으로
            </Text>
            <Text color="white" padding="10px 0" size="2em">
              데이터 중심의 디지털 전환을 이끌어나갑니다.
            </Text>
          </>
        )}
        {useGetMobile() ? (
          <>
            <MobileImage
              src={require("../image/arrow.svg").default}
              rotate="true"
              onClick={() => {
                moveScroll(false);
              }}
            />
          </>
        ) : (
          <>
            <Img
              src={require("../image/arrow.svg").default}
              onClick={() => {
                moveScroll(check_Tablet());
              }}
            />
          </>
        )}
      </TextGroup>
      <Video
        autoPlay
        muted
        playsInline
        loop
        src={require("../video/main.mp4")}
      ></Video>
    </Parents>
  );
};
export default TopContents;
