import React, { useEffect } from "react";
import styled from "styled-components";

const Parents = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 0 20px;
  z-index: 99;
`;
const Child = styled.div`
  background: rgb(255, 0, 0, 0.3);
`;

const Grid = () => {
  useEffect(() => {
    keyDown();
  }, []);
  let gridView = false;
  const keyDown = () => {
    window.addEventListener("keydown", (e) => {
      if (e.key === "q") {
        if (gridView) {
          document.getElementById("grid").style.display = "none";
          gridView = false;
        } else {
          document.getElementById("grid").style.display = "grid";
          gridView = true;
        }
      }
    });
  };
  return (
    <Parents id="grid">
      <Child></Child>
      <Child></Child>
      <Child></Child>
      <Child></Child>
      <Child></Child>
      <Child></Child>
      <Child></Child>
      <Child></Child>
      <Child></Child>
      <Child></Child>
      <Child></Child>
      <Child></Child>
    </Parents>
  );
};
export default Grid;
