import React, { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { images } from "../../Images";
import { slidedata } from "./data";
import { Text } from "../../../../Components/Components";
import Swipe from "react-easy-swipe";

const Parents = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
`;
const LeftButton = styled.img`
  height: 9%;
  cursor: pointer;
  position: absolute;
  left: 0;
  margin: 0 4%;
  z-index: 3;
  min-width: 35px;
  min-height: 35px;
  margin-top: 18%;
`;
const RightButton = styled.img`
  transform: rotateY(180deg);
  height: 9%;
  position: absolute;
  cursor: pointer;
  right: 0;
  margin: 0 4%;
  min-width: 35px;
  min-height: 35px;
  margin-top: 18%;
  z-index: 3;
`;
const SlideGroup = styled.div`
  width: 100%;
  height: 80%;
  display: inline-block;
  white-space: nowrap;
  transition: transform ${(e) => (e.swipe ? "0.5s" : "0s")};
  transform: translateX(${(e) => e.positionx});
`;
const Contents = styled.div`
  display: inline-flex;
  width: 100%;
  height: 100%;
  align-items: center;
  flex-direction: column;
  word-break: break-all;
  white-space: normal;
  transform: ${(e) => e.move || 0};
  transition: all 1s ease;
`;
const Title = styled.div`
  width: 90%;
  display: flex;
  box-sizing: border-box;
`;
const BottomSlideButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const SlideElipse = styled.div`
  border-radius: 100%;
  border: 1px solid black;
  width: 12px;
  height: 12px;
  margin: 0 5px;
  transition: all 0.5s ease;
  ${(e) =>
    e.select &&
    css`
      border: 1px solid #2f80ed;
      background: #2f80ed;
      width: 35px;
      height: 12px;
      border-radius: 10px;
    `}
`;
const TextGroup = styled.div`
  width: 88%;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  flex-direction: column;
  word-break: break-all;
  white-space: normal;
  margin: ${(e) => e.margin || "0"};
`;
const Image = styled.img`
  width: 80%;
`;
const ImageGroup = styled.div`
  margin-top: 15%;
  width: 90%;
  display: flex;
  justify-content: center;
`;
const AlignButton = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  overflow: hidden;
  & > img:nth-child(1),
  img:nth-last-child(1) {
    justify-content: center;
    align-items: center;
  }
`;

const MobileSlide = () => {
  const [select, setSelect] = useState(0);
  const [positionX, setPositionX] = useState(0);
  const [swipe, setSwipe] = useState(true);
  const [windowFocus, setWindowFocus] = useState(false);
  const timeRef = useRef(null);

  // auto slide
  // useEffect(async () => {
  //   if (windowFocus) {
  //     await TimeStart();
  //   } else {
  //     clearInterval(timeRef.current);
  //   }
  // }, [windowFocus]);
  // window.addEventListener("scroll", () => {
  //   let childY = document.getElementById("mobileOurCuture").offsetTop;
  //   let tabbarHeight = document.getElementById("tabbar").offsetHeight;
  //   if (childY - tabbarHeight <= document.documentElement.scrollTop) {
  //     setWindowFocus(true);
  //   } else {
  //     setWindowFocus(false);
  //   }
  // });
  // const TimeStart = () => {
  //   let sec = 0;
  //   timeRef.current = setInterval(() => {
  //     if (sec == slidedata.length - 1) {
  //       sec = 0;
  //     } else if (sec < slidedata.length - 1) {
  //       sec++;
  //     }
  //     setSelect(sec);
  //   }, 2000);
  // };

  const moveEvent = (t) => {
    if (t == "go") {
      if (select == slidedata.length - 1) {
        setSelect(0);
      } else {
        setSelect(select + 1);
      }
    } else if (t == "back") {
      if (select == 0) {
        setSelect(slidedata.length - 1);
      } else {
        setSelect(select - 1);
      }
    }
  };

  const lockY = (state) => {
    let container = document.getElementById("OurMove");
    if (state) {
      container.addEventListener(
        "scroll",
        (e) => {
          e.preventDefault();
          e.stopImmediatePropagation();
        },
        {
          passive: false,
        }
      );
      container.style.touchAction = "pan-x";
    } else {
      container.style.touchAction = "pan-y";
    }
  };

  const moveButtonEvent = (n) => {
    setSelect(n);
  };

  const onSwipeMove = (position) => {
    setSwipe(false);
    lockY(true);
    if (position.x < -20) {
      setPositionX(position.x);
      return;
    } else if (position.x > 20) {
      setPositionX(position.x);
      return;
    }
  };

  const onSwipeEnd = (position) => {
    if (positionX < -40) {
      if (select >= slidedata.length - 1) {
        setSelect(0);
      } else {
        setSelect(select + 1);
      }
    }
    if (positionX > 40) {
      if (select == 0) {
        setSelect(slidedata.length - 1);
      } else {
        setSelect(select - 1);
      }
    }
    setTimeout(() => {
      lockY(false);
    }, 1000);
    setPositionX(0);
    setSwipe(true);
  };

  return (
    <Parents id="mobileOurCuture">
      <Title>
        <Text color="#2F80ED" bold="bold" size="1.3em">
          OUR CULTURE
        </Text>
      </Title>
      <AlignButton>
        <LeftButton
          src={images.slide_button}
          onClick={() => {
            moveEvent("back");
          }}
        />
        <Swipe
          onSwipeMove={onSwipeMove}
          onSwipeEnd={onSwipeEnd}
          style={{ overflow: "hidden" }}
        >
          <SlideGroup
            id="OurMove"
            positionx={`calc( ${-100 * select}%)`}
            swipe={swipe}
          >
            {slidedata.map((r, index) => (
              <Contents key={`title${index}`}>
                <TextGroup>
                  {r.title.map((f, index) => (
                    <Text
                      bold="bold"
                      size="1.3em"
                      key={`ourcuturetitle${index}`}
                      lineHeight="1.3em"
                    >
                      {f.mobileText || f.text}
                    </Text>
                  ))}
                </TextGroup>
                <TextGroup margin="7% 0 0 0">
                  {r.contents.map((mt, index) => (
                    <Text
                      size="1em"
                      lineHeight="1.3em"
                      margin="0 0 2px 0"
                      key={`outcuture${index}`}
                    >
                      {mt.text}
                    </Text>
                  ))}
                </TextGroup>
                <ImageGroup>
                  <Image src={r.image} />
                </ImageGroup>
              </Contents>
            ))}
          </SlideGroup>
        </Swipe>
        <RightButton
          src={images.slide_button}
          onClick={() => {
            moveEvent("go");
          }}
        />
      </AlignButton>
      <BottomSlideButton>
        {slidedata.map((r, index) => (
          <SlideElipse
            key={`elipse${index}`}
            select={select == index}
            onClick={() => {
              moveButtonEvent(index);
            }}
          />
        ))}
      </BottomSlideButton>
    </Parents>
  );
};
export default MobileSlide;
