import React, { useState } from "react";
import styled, { css } from "styled-components";
import { menutext } from "./data";
import { Text } from "../../../../Components/Components";
import { is_Mobile, useGetMobile, is_Browser } from "../../../../Util/Util";
import { animateScroll as scroll } from "react-scroll";

const Parents = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.7);
`;
const Contents = styled.div`
  display: flex;
  width: ${(e) => e.mobile || "65.5%"};
  justify-content: space-between;
  align-items: center;
  ${(e) =>
    e.mobile &&
    css`
      width: 100%;
    `}
`;
const Child = styled.div`
  padding: 10px 0;
  height: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  word-break: break-all;
  ${(e) =>
    e.border &&
    css`
      border-bottom: 2px solid #2f80ed;
    `}
`;

const TopMenuBar = ({ setSelect, select }) => {
  let textOption = {
    cursor: "pointer",
  };
  const moveFoodPlan = () => {
    let foodplanHeight = document.getElementById("foodPlanSlide").offsetTop;
    let tabbarHeight = document.getElementById("tabbar").offsetHeight;
    if (document.documentElement.scrollTop < foodplanHeight - tabbarHeight) {
      scroll.scrollTo(foodplanHeight - tabbarHeight);
    }
  };
  return (
    <Parents mobile={useGetMobile() && "none"}>
      <Contents mobile={useGetMobile()}>
        {menutext.map((r) => (
          <Child
            key={r.id}
            border={r.id === select}
            onClick={() => {
              setSelect(r.id);
              moveFoodPlan();
            }}
          >
            {is_Mobile() && (r.id === 3 || r.id === 4) ? (
              r.mobile.map((f, index) => (
                <Text
                  {...textOption}
                  size="1em"
                  color={r.id === select ? "#374252" : "#BDBDBD"}
                  bold={r.id === select && "bold"}
                  lineHeight="1.3em"
                >
                  {f.text}
                  <br />
                </Text>
              ))
            ) : (
              <Text
                {...textOption}
                size="1em"
                color={r.id === select ? "#374252" : "#BDBDBD"}
                bold={r.id === select && "bold"}
              >
                {r.name}
              </Text>
            )}
          </Child>
        ))}
      </Contents>
    </Parents>
  );
};
export default TopMenuBar;
