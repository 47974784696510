import { useRoutes } from "react-router-dom";
import { Main, PrivacyPolicy } from "../Page";

const Router = () => {
  const routes = useRoutes([
    {
      path: "",
      element: <Main />,
    },
    {
      path: "service",
      element: <PrivacyPolicy />,
      children: [
        {
          path: ":id",
          element: <PrivacyPolicy />,
        },
      ],
    },
    {
      path: "*",
      element: <Main />,
    },
  ]);
  return routes;
};
export default Router;
