import React from "react";
import styled, { css } from "styled-components";
import { Text } from "../../../../Components/Components";
import { is_Browser } from "../../../../Util/Util";
import { images } from "../../Images";
import { foodplan } from "./data";
import SlideItem from "./SlideChild/SlideItem";

const Contents = styled.div`
  display: flex;
  width: 95%;
  min-height: 50%;
  margin-top: 5%;
  justify-content: center;
  align-items: center;
`;
export const MainContents = styled.div`
  display: inline-block;
  width: 100%;
  height: 100%;
  transform: ${(e) => e.move || 0};
  transition: all 1s ease;
`;
export const LeftContents = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 0;
  margin: 0;
  width: 55%;
  height: 100%;
  margin-right: 20px;
  overflow: hidden;
  word-break: break-all;
`;
export const RightContents = styled.div`
  width: 45%;
  height: 100%;
  border-radius: 10px;
  margin-left: 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const LeftButton = styled.img`
  width: 4%;
  height: 9%;
  min-width: 50px;
  min-height: 50px;
  ${!is_Browser() &&
  css`
    max-height: 50px;
    max-width: 50px;
  `}
  margin: 0 4%;
  cursor: pointer;
`;
const RightButton = styled.img`
  transform: rotateY(180deg);
  width: 4%;
  height: 9%;
  min-width: 50px;
  min-height: 50px;
  ${!is_Browser() &&
  css`
    max-height: 50px;
    max-width: 50px;
  `}
  margin: 0 4%;
  cursor: pointer;
`;
export const ListParents = styled.div`
  text-align: left;
  padding: 0;
  display: flex;
  flex-direction: column;
  word-break: break-all;
  white-space: pre-wrap;
`;
const SlideContents = styled.div`
  width: 73%;
  height: 100%;
  white-space: nowrap;
  overflow: hidden;
`;
export const AlignContents = styled.div`
  display: flex;
  width: 95%;
  height: 100%;
  justify-content: space-around;
  flex-direction: row;
  word-spacing: unset;
  word-break: break-all;
  margin: 0 auto;
  @media screen and (max-width: 1600px) {
    justify-content: space-between;
    & > div:nth-last-child(1) {
      margin-left: 20px;
    }
  }
`;
export const Image = styled.img`
  display: ${(e) => e.display || "block"};
  width: ${(e) => e.width || "95%"};
  height: ${(e) => e.height || "95%"};
  border-radius: 8px;
  box-shadow: 2px 2px 10px gray;
  object-fit: cover;
`;
export const TextGroup = styled.div`
  display: flex;
  flex-direction: row;
`;

const Slide = ({ setSelect, select }) => {
  const moveButton = (t) => {
    if (t == "go") {
      if (select == 4) {
        setSelect(0);
      } else {
        setSelect(select + 1);
      }
    } else if (t == "back") {
      if (select == 0) {
        setSelect(4);
      } else {
        setSelect(select - 1);
      }
      return;
    }
  };

  return (
    <Contents>
      <LeftButton
        src={images.slide_button}
        onClick={() => {
          moveButton("back");
        }}
      />
      <SlideContents>
        <SlideItem select={select} />
      </SlideContents>
      <RightButton
        src={images.slide_button}
        onClick={() => {
          moveButton("go");
        }}
      />
    </Contents>
  );
};
export default Slide;
