import { isMobile, isTablet } from "react-device-detect";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { mobiles } from "../Recoil/recoil";

export const is_Browser = () => {
  const agent = navigator.userAgent.toLowerCase();
  if (
    agent.indexOf("edge") > -1 ||
    agent.indexOf("edg/") > -1 ||
    agent.indexOf("opr") > -1 ||
    agent.indexOf("chrome") > -1 ||
    agent.indexOf("firefox") > -1 ||
    agent.indexOf("safari") > -1
  ) {
    return true;
  } else {
    return false;
  }
};

// 모바일 유무
export const is_Mobile = () => {
  let width = window.innerWidth;
  let height = window.innerHeight;
  let minWidth = 960;
  let minHeight = 600;
  if (height > width) {
    return true;
  } else {
    if (width >= minWidth && height >= minHeight) {
      return false;
    }
  }
};

export const useGetMobile = () => {
  const returnmobile = useRecoilValue(mobiles);
  return returnmobile;
};

export const check_Tablet = () => {
  let defaultValue = false;
  tabletArray.forEach((r) => {
    if (navigator.userAgent.toLowerCase().indexOf(r.name) > -1) {
      defaultValue = true;
    }
  });
  return defaultValue;
};

// // 테블릿 가로, 세로 판별
// export const tablet_orientation = () => {
//   // 가로 landscape
//   // 세로 portrait
//   return is_Tablet() && window.matchMedia("(orientation: landscape )").matches
//     ? true
//     : false;
// };

const tabletArray = [
  {
    name: "android",
  },
  {
    name: "mobile",
  },
  {
    name: "samsungbrowser",
  },
];
