import React from "react";
import IntroFoodPlan from "./FoodPlan/IntroFoodPlan";
import Market from "./FoodPlan/Market";

const FoodPlan = () => {
  return (
    <>
      <IntroFoodPlan />
      <Market />
    </>
  );
};
export default FoodPlan;
