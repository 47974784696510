import React from "react";
import styled, { css } from "styled-components";
import { Text } from "../../../../Components/Components";
import { check_Tablet, useGetMobile } from "../../../../Util/Util";
import { NavLink } from "react-router-dom";

const Parents = styled.div`
  width: 100%;
  height: 20%;
  max-height: ${(e) => (e.mobile ? "60px" : "130px")};
  background: #4f4f4f;
  box-sizing: border-box;
`;
const Image = styled.img`
  height: 46%;
  margin-right: 20px;
  max-height: ${(e) => (e.mobile ? "25px" : "40px")};
`;
const ContentsAlign = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: ${(e) => (e.mobile ? "center" : "space-between")};
  align-items: center;
  flex-direction: column;
`;
const Contents = styled.div`
  width: 100%;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  &:nth-child(1) {
    border-bottom: ${(e) => !e.mobile && "1px solid #cbcbcb"};
  }
`;
const MarginChild = styled.div`
  width: ${(e) => (e.mobile ? "90%" : "66%")};
  display: flex;
  align-items: center;
  ${(e) =>
    e.mobile &&
    css`
      justify-content: space-between;
    `}
  ${(e) =>
    e.alignRow &&
    css`
      flex-direction: column;
      align-items: flex-start;
    `}
`;

const NavLinkCusTom = styled(NavLink)`
  text-decoration: none;
  white-space: nowrap;
`;

const NavLinkContents = styled.div`
  display: flex;
  ${(e) =>
    e.mobile &&
    css`
      width: 100%;
    `}
  justify-content: flex-end;
  flex-direction: row;
`;

const Footer = () => {
  const newWindow = (path) => {
    let width = window.screen.width / 2;
    let height = window.screen.height / 2;
    let popupX = window.screen.width / 2 - width / 2;
    let popupY = window.screen.height / 2 - height / 2;
    window.open(
      path,
      "개인정보 처리방침",
      `toolbar=no, menubar=no, status=no, width=${width}px, height=${height}px, location=no, left=${popupX}px, top=${popupY}px`
    );
  };
  return (
    <Parents mobile={useGetMobile()}>
      <ContentsAlign mobile={useGetMobile()}>
        <Contents mobile={useGetMobile()}>
          <MarginChild mobile={useGetMobile()}>
            <Image
              mobile={useGetMobile()}
              src={
                useGetMobile()
                  ? require("../../image/mobileLogo.svg").default
                  : require("../../image/logo.svg").default
              }
            />
            {useGetMobile() && (
              <Text size="0.7em" color="white">
                Copyright 2022 MediengSystem. All rights reserved.
              </Text>
            )}
            {/* <NavLinkContents mobile={!useGetMobile() ? "true" : ""}>
              <Text
                margin="0 10px"
                size="0.7em"
                color="white"
                cursor="pointer"
                onClick={() => {
                  newWindow("/service/privacyPolicy");
                }}
              >
                개인정보 처리방침
              </Text>
            </NavLinkContents> */}
          </MarginChild>
        </Contents>
        {!useGetMobile() && (
          <Contents>
            <MarginChild alignRow="true">
              <Text color="white" size="0.6em" margin="0 0 10px 0" bold="300">
                (55011) 전라북도 전주시 완산구 아중로 33 (중노송동)
                전주정보문화산업진흥원 문화산업지원센터 205호
              </Text>
              <Text color="white" size="0.6em" bold="300">
                Copyright 2022 MediengSystem. All rights reserved.
              </Text>
            </MarginChild>
          </Contents>
        )}
      </ContentsAlign>
    </Parents>
  );
};
export default Footer;
