export default {
  privacyPolicy: [
    {
      bigTitle: "개인정보 처리방침",
      title: "",
      content: [
        {
          text: "메디앙시스템('www.medieng.com'이하 '회사')은 「개인정보 보호법」 제30조에 따라 정보주체의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을 수립·공개합니다.",
        },
      ],
    },
    {
      title: "제1조 (개인정보의 처리 및 보유 기간)",
      content: [
        {
          text: "①  '회사'는 개인정보를 수집하는 목적은 회원제 서비스 운영을 위해 이용자의 신분과 서비스 이용의사를 확인하기 위함입니다.",
          detail: [
            {
              text: "‘회사’는 최초 회원가입 시 서비스의 본질적 기능을 수행하기 위해 반드시 필요한 최소한의 정보만을 수집하고 있습니다.",
            },
            {
              text: "처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며 이용 목적이 변경되는 경우에는 「개인정보 보호법」 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.",
            },
          ],
        },
        {
          text: "② ’회사’는 법령에 따른 개인정보 보유·이용기간 또는 정보주체로부터 개인정보를 수집 시에 동의받은 개인정보 보유·이용기간 내에서 개인정보를 처리·보유합니다.",
          detail: [
            {
              text: "",
            },
          ],
        },
        {
          text: "③ 각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.",
          detail: [
            {
              text: "1. 회원가입 및 관리",
            },
          ],
          table: {
            title: " |목적|수집정보|운영 근거|보유 기간",
            titleLength: 5,
            contents: [
              {
                text: "푸드플랜 농가/푸드플랜 소비자|가입의사 확인|아이디, 비밀번호|이용자 동의|회원탈퇴시",
              },
              {
                text: "농가/푸드플랜 소비자|이용자 식별|휴대전화 번호|이용자 동의|목적 달성시 즉시 파기",
              },
            ],
          },
          list: [
            {
              title: "목적",
              content: [
                {
                  text: "푸드플랜 농가/푸드플랜 소비자: 가입의사 확인",
                },
                {
                  text: "농가/푸드플랜 소비자: 이용자 식별",
                },
              ],
            },
            {
              title: "수집정보",
              content: [
                {
                  text: "푸드플랜 농가/푸드플랜 소비자: 아이디, 비밀번호",
                },
                {
                  text: "농가/푸드플랜 소비자: 휴대전화 번호",
                },
              ],
            },
            {
              title: "운영 근거",
              content: [
                {
                  text: "푸드플랜 농가/푸드플랜 소비자: 이용자 동의",
                },
                {
                  text: "농가/푸드플랜 소비자: 이용자 동의",
                },
              ],
            },
            {
              title: "보유 기간",
              content: [
                {
                  text: "푸드플랜 농가/푸드플랜 소비자: 회원탈퇴시",
                },
                {
                  text: "농가/푸드플랜 소비자: 목적 달성시 즉시 파기",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      title: "제2조 (개인정보의 파기절차 및 파기방법)",
      content: [
        {
          text: "① ‘회사’는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다.",
        },
        {
          text: "② 정보주체로부터 동의받은 개인정보의 처리목적이 달성되었을 경우 즉시 파기처리합니다.",
        },
        {
          text: "③ 개인정보 파기의 절차 및 방법은 다음과 같습니다.",
          detail: [
            {
              text: "1. 파기절차",
            },
            {
              text: "‘회사’는 파기 사유가 발생한 개인정보를 선정하고, 메디앙시스템의 개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다.",
            },
            {
              text: "2. 파기방법",
            },
            {
              text: "전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적 방법을 사용합니다.",
            },
            {
              text: "종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.",
            },
          ],
        },
      ],
    },
    {
      title:
        "제3조 (정보주체와 법정대리인의 권리·의무 및 그 행사방법에 관한 사항)",
      content: [
        {
          text: "① 정보주체는 ‘회사’에 대해 언제든지 개인정보 열람·정정·삭제·처리정지 요구 등의 권리를 행사할 수 있습니다.",
        },
        {
          text: "② 제1항에 따른 권리 행사는메디앙시스템에 대해 「개인정보 보호법」 시행령 제41조제1항에 따라 서면, 전자우편 등을 통하여 하실 수 있으며 메디앙시스템은 이에 대해 지체 없이 조치하겠습니다.",
        },
        {
          text: "③ 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다.이 경우 “개인정보 처리 방법에 관한 고시(제2020-7호)” 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.",
        },
        {
          text: "④ 개인정보 열람 및 처리정지 요구는 「개인정보 보호법」 제35조 제4항, 제37조 제2항에 의하여 정보주체의 권리가 제한 될 수 있습니다.",
        },
        {
          text: "⑤ 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.",
        },
        {
          text: "⑥ ‘회사’는 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.",
        },
      ],
    },
    {
      title: "제4조 (개인정보의 안전성 확보조치에 관한 사항) ",
      content: [
        {
          text: "‘회사’는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.",
          detail: [
            {
              text: "1. 개인정보 취급 관련 안정성 확보를 위해 정기적(분기 1회)으로 자체 감사를 실시하고 있습니다.",
            },
            {
              text: "2. 개인정보 취급 직원을 최소화하고 정기적으로 교육을 실시하여 개인정보를 관리하는 대책을 시행하고 있습니다.",
            },
            {
              text: "3. 개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고 있습니다.",
            },
            {
              text: "4. 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치하고 주기적인 갱신·점검을 하며 외부로부터 접근이 통제된 구역에 시스템을 설치하고 기술적/물리적으로 감시 및 차단하고 있습니다.",
            },
            {
              text: "5. 이용자의 개인정보는 비밀번호는 암호화 되어 저장 및 관리되고 있어, 본인만이 알 수 있으며 중요한 데이터는 파일 및 전송 데이터를 암호화 하거나 파일 잠금 기능을 사용하는 등의 별도 보안기능을 사용하고 있습니다.",
            },
            {
              text: "6. 개인정보처리시스템에 접속한 기록을 최소 1년 이상 보관, 관리하고 있습니다.",
            },
            {
              text: "7. 개인정보를 처리하는 데이터베이스 시스템에 대한 접근권한의 부여,변경,말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.",
            },
          ],
        },
      ],
    },
    {
      title:
        "제5조 (개인정보를 자동으로 수집하는 장치의 설치·운영 및 그 거부에 관한 사항)",
      content: [
        {
          text: "‘회사’는 정보주체의 이용정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용하지 않습니다.",
        },
      ],
    },
    {
      title: "제6조 (개인정보 보호책임자에 관한 사항)",
      content: [
        {
          text: "① ‘회사’는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.",
          detail: [
            {
              text: "⦁ 개인정보 보호책임자",
            },
          ],
          deepDetail: [
            {
              text: "∘ 성명: 김재환(임시)",
            },
            {
              text: "∘ 직책 : CS",
            },
            {
              text: "∘ 직급 : 사원",
            },
            {
              text: "∘ 연락처 : 010-7103-1184, rla1269@medieng.com",
            },
          ],
        },
        {
          text: "② 정보주체께서는 ‘회사’의 서비스(또는 사업)을 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다. ‘회사’는 정보주체의 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다.",
        },
      ],
    },
    {
      title: "제7조(개인정보의 열람청구를 접수·처리하는 부서)",
      content: [
        {
          text: "정보주체는 ｢개인정보 보호법｣ 제35조에 따른 개인정보의 열람 청구를 아래의 부서에 할 수 있습니다.",
        },
        {
          text: "‘회사’는 정보주체의 개인정보 열람청구가 신속하게 처리되도록 노력하겠습니다.",
          detail: [
            {
              text: "• 개인정보 열람청구 접수·처리 부서",
            },
          ],
          deepDetail: [
            {
              text: "∘ 부서명 : 메디앙시스템 고객센터",
            },
            {
              text: "∘ 담당자 : 김재환",
            },
            {
              text: "∘ 연락처 : 010-7103-1184, rla1269@medieng.com",
            },
          ],
        },
      ],
    },
    {
      title: "제8조(정보주체의 권익침해에 대한 구제방법)",
      content: [
        {
          text: "정보주체는 개인정보침해로 인한 구제를 받기 위하여 개인정보분쟁조정위원회, 한국인터넷진흥원 개인정보침해신고센터 등에 분쟁해결이나 상담 등을 신청할 수 있습니다. ",
        },
        {
          text: "이 밖에 기타 개인정보침해의 신고, 상담에 대하여는 아래의 기관에 문의하시기 바랍니다.",
          detail: [
            {
              text: "1. 개인정보분쟁조정위원회 : (국번없이) 1833-6972 (www.kopico.go.kr)",
            },
            {
              text: "2. 개인정보침해신고센터 : (국번없이) 118 (privacy.kisa.or.kr)",
            },
            {
              text: "3. 대검찰청 : (국번없이) 1301 (www.spo.go.kr)",
            },
            {
              text: "4. 경찰청 : (국번없이) 182 (ecrm.cyber.go.kr)",
            },
          ],
        },
        {
          text: "「개인정보보호법」제35조(개인정보의 열람), 제36조(개인정보의 정정·삭제), 제37조(개인정보의 처리정지 등)의 규정에 의한 요구에 대 하여 공공기관의 장이 행한 처분 또는 부작위로 인하여 권리 또는 이익의 침해를 받은 자는 행정심판법이 정하는 바에 따라 행정심판을 청구할 수 있습니다.",
        },
        {
          text: "※ 행정심판에 대해 자세한 사항은 중앙행정심판위원회(www.simpan.go.kr) 홈페이지를 참고하시기 바랍니다.",
        },
      ],
    },
    {
      title: "제9조(개인정보 처리방침 변경)",
      content: [
        {
          text: "① 이 개인정보처리방침은 2022년 1월 1부터 적용됩니다.",
        },
      ],
    },
  ],
  member: [
    {
      bigTitle: "회원 약관",
      title: "제1장 총칙",
      content: [{}],
    },
    {
      title: "제1조 (목적)",
      content: [
        {
          text: "본 약관은 메디앙시스템(이하 ‘회사’)이 운영하는 모바일 애플리케이션(이하 ‘애플리케이션’)을 통해 서비스를 제공함에 있어서 회사와 이용자의 이용조건 및 제반 절차, 기타 필요한 사항의 규정을 목적으로 합니다.",
        },
      ],
    },
    {
      title: "제2조 (용어의 정의)",
      content: [
        {
          text: "본 약관에서 사용하는 용어는 아래와 같습니다.",
        },
        {
          text: "① ‘애플리케이션’이라 함은 회사가 이용자에게 로컬푸드 사업체의 회원제 서비스를 제공하기 위하여 스마트폰 또는 기타 단말기에서 이용할 수 있도록 제작, 운영하는 프로그램을 말합니다. 현재 회사가 운영하는 애플리케이션의 이름은 아래와 같습니다.",
          detail: [
            {
              text: "• 푸드플랜 농가",
            },
            {
              text: "• 푸드플랜 소비자",
            },
          ],
        },
        {
          text: "② ‘서비스’라 함은 로컬푸드 사업체 회원제 서비스의 사용자 식별 및 거래정보를 송 수신을 대행하는 용역 플랫폼 서비스입니다. ",
        },
        {
          text: "③ ‘이용자’라 함은 본 서비스를 이용하기 위해 어플리케이션을 설치하고 회사가 제공하는 서비스를 이용하는 인증 회원 및 비회원을 말합니다.",
        },
        {
          text: "④ ‘인증 회원’이라 함은 본 서비스를 이용하기 위해 본 약관에 동의하고 회사와 서비스 이용계약을 체결하여 개인 ID와 소속된 로컬푸드 매장의 DB에 접근 권한을 부여받은 이용자를 말합니다.",
        },
        {
          text: "⑤ ‘비회원’이라 함은 회사와 서비스 이용계약을 체결하지 않은 채 회사가 제공하는 서비스를 이용하는 이용자를 말합니다.",
        },
        {
          text: "⑥ ‘서비스 이용계약’이라 함은 회사가 인증 회원을 위해 제공하는 서비스를 계속적으로 이용하기 위하여 회사와 이용자 사이에 체결되는 계약을 말합니다.",
        },
        {
          text: "⑦ ‘이용자 ID’ 또는 ‘ID’라 함은 인증 회원의 식별 및 서비스 이용을 위하여 인증 회원이 선정하거나 회사가 부여하는 문자와 숫자의 조합을 말합니다.",
        },
        {
          text: "⑧ ‘비밀번호’라 함은 회사의 서비스를 이용하려는 사람이 ‘이용자 ID’를 부여 받은 자와 동일인임을 확인하고 인증 회원의 권익을 보호하기 위하여 인증 회원이 선정하거나 회사가 부여하는 문자와 숫자의 조합을 말합니다.",
        },
        {
          text: "⑨ ‘거래내역’이라 함은 인증 회원과 로컬푸드 사업체 사이에 발생한 거래에 관련된 정보들입니다.",
        },
        {
          text: "⑩ ‘전자영수증’이라 함은 인증 회원과 로컬푸드 사업체 사이에 발생한 거래 내용의 증서를 애플리케이션으로 표기한 것입니다.",
        },
        {
          text: "⑪ ‘거래정보’라 함은 이용자와 로컬푸드 사업체의 거래간 발생한 거래 내역 및 전자영수증을 말합니다.",
        },
      ],
    },
    {
      title: "제3조 (약관의 명시와 개정)",
      content: [
        {
          text: "① 회사는 본 약관의 내용과 상호, 영업소 소재지, 사업자등록번호, 연락처 등을 이용자가 알 수 있도록 초기 화면에 게시하거나 기타의 방법으로 이용자에게 공지합니다. 약관의 내용은 이용자가 사이트 등의 링크를 통한 연결화면을 통하여 볼 수 있도록 할 수 있습니다.",
        },
        {
          text: "② 회사는 약관의 규제 등에 관한법률, 전기통신기본법, 전기통신사업법, 정보통신망이용촉진등에관한법률 등 관련법을 위배하지 않는 범위에서 본 약관을 개정할 수 있습니다.",
        },
        {
          text: "③ 회사가 약관을 개정할 경우에는 개정 약관 적용일 최소 7일전(약관의 변경이 인증 회원에게 불리하거나 권리•의무의 중요한 변경의 경우에는 30일전)부터 웹사이트 초기화면 공지사항 또는 애플리케이션을 통해 사전 공지합니다.",
        },
        {
          text: "④ 인증 회원은 변경된 약관에 대해 거부할 권리가 있으며, 인증 회원은 변경된 약관이 공지된 지 15일 이내에 변경 약관에 대한 거부 의사를 표시할 수 있습니다. 만약, 인증 회원이 거부 의사를 표시하지 않고 서비스를 계속 이용하는 경우, 회사는 개인회원이 변경 약관 적용에 동의하는 것으로 봅니다.",
        },
        {
          text: "⑤ 인증 회원이 제4항에 따라 변경 약관 적용을 거부할 의사를 표시한 경우, 회사는 인증 회원에게 15일의 기간을 정하여 사전 통지 후 해당 인증 회원과의 서비스 이용계약 또는 별도로 체결된 계약을 해지할 수 있습니다.",
        },
      ],
    },
    {
      title: "제4조 (약관 외 준칙)",
      content: [
        {
          text: "① 약관에서 규정하지 않은 사항에 관해서는 약관의 규제 등에 관한 법률, 정보통신망 이용 촉진 및 정보보호 등에 관한 법률, 개인정보 보호법, 전기통신기본법, 전기통신사업법 등의 관계법령에 따라 규율됩니다.",
        },
        {
          text: "② 회원이 회사와 개별 계약을 체결하여 서비스를 이용하는 경우, 인증 회원의 서비스 이용과 관련된 권리 의무는 순차적으로 개별 계약, 개별 서비스 이용약관, 본 약관에서 정한 내용에 따라 규율됩니다.",
        },
      ],
    },
    {
      title: "제5조 (개인회원에 대한 고지, 통지 및 공지)",
      content: [
        {
          text: "회사는 인증 회원에게 서비스 정책, 이용약관, 개인정보 처리방침 등 일체의 약관, 서비스 이용 관련 일정한 사항이나 정보를 알리거나 안내할 목적으로 각종 고지나 통지를 이행할 수 있으며, 홈페이지의 화면에 일정한 사항을 게시하여 공지함으로써 각 인증 회원에 대한 각종 고지나 통지를 갈음할 수 있습니다.",
        },
      ],
    },
    {
      bigTitle: "제2장 회원가입",
      title: "",
      content: [{}],
    },
    {
      title: "제6조 (서비스 이용계약의 성립)",
      content: [
        {
          text: "① 서비스 이용계약은 인증 회원 서비스를 이용하고자 하는 자(이하 “이용희망자”라 합니다)의 본 약관과 개인정보 처리방침의 내용에 대한 동의 및 회원 가입 신청에 대하여 회사가 승낙함으로써 성립합니다.",
        },
        {
          text: "② 어플리케이션을 통한 회원 가입시 이용희망자는 본 약관 및 개인정보 처리방침에 대한 동의 표시 및 회원 가입 신청을 하여야 하며, 회사는 이용희망자가 본 약관 및 개인정보 처리방침의 내용을 읽고 동의한 것으로 봅니다.",
        },

        {
          text: "③ 이용희망자가 제2항에 따라 회원 가입 신청을 한 후, 회사가 이용희망자에게 승낙의 의사가 담긴 페이지를 통해 통지함으로써 서비스 이용계약이 성립합니다.",
        },
        {
          text: "④ 이용자는 본 약관 및 회사의 개인정보 처리방침에서 정한 항목을 제공하여야 합니다.",
        },
        {
          text: "⑤ 회사는 본인 확인을 위하여 필요시 이용자에게 본인 인증을 요구할 수 있습니다. 이 경우 이용자는 휴대전화 번호인증을 통해 본인 인증절차를 거쳐야 하며, 이용자가 본인 인증절차를 거치지 않은 경우, 회사에 대하여 일체의 권리를 주장할 수 없습니다.",
        },
      ],
    },
    {
      title: "제7조 (개인회원 가입 신청의 승낙과 제한)",
      content: [
        {
          text: "① 회사는 전조의 규정에 의한 이용희망자에 대하여 다음 각 호의 사유를 모두 충족할 경우 회사가 제공하는 절차에 따라 인증 회원 가입을 승낙합니다.",
          detail: [
            {
              text: "1. 회사의 업무수행 및 서비스 제공을 위한 설비의 여유 • 기술상 지장이 없는 경우",
            },
            {
              text: "2. 본 약관에서 정하는 승낙 제한 또는 거절, 보류 사유에 해당하지 않는 경우",
            },
          ],
        },
        {
          text: "② 다음 각 호 중 어느 하나에 해당할 경우, 회사는 이용희망자의 개인회원 가입 신청을 승낙하지 아니합니다.",
          detail: [
            {
              text: "1. 인증 회원 가입 신청시에 타인의 휴대전화를 도용한 경우",
            },
            {
              text: "2. 인증 회원 가입 신청시에 인증 회원정보를 허위로 기재한 경우 ",
            },
          ],
        },
      ],
    },
    {
      title: "제3장 서비스의 이용",
    },
    {
      title: "제8조 (서비스의 내용)",
      content: [
        {
          text: "① 회사가 제공하는 서비스의 내용은 다음 각 호와 같습니다.",
          detail: [
            {
              text: "1. 소속 로컬푸드의 회원 식별 서비스",
            },
            {
              text: "2. 소속 로컬푸드의 전자영수증 조회 서비스",
            },
            {
              text: "3. 소속 로컬푸드의 거래내역 조회 서비스",
            },
          ],
        },
        {
          text: "② 회사는 필요한 경우 서비스의 내용을 추가 또는 변경할 수 있습니다. 다만, 서비스의 내용의 추가 또는 변경으로 인하여 인증 회원의 권리•의무에 중요한 영향을 미치는 경우, 회사는 추가 또는 변경된 서비스 내용을 그 적용일로부터 30일 전에 공지합니다.",
        },
      ],
    },
    {
      title: "제9조 (거래정보의 조회)",
      content: [
        {
          text: "① 안정적인 서비스 제공을 위한 테스트 및 모니터링 등의 용도로 인증 회원의 거래정보가 열람될 수 있습니다.",
        },
        {
          text: "② 회사는 로컬푸드의 인증 회원의 거래정보에 대하여 일절 관여하지 않으며 거래정보의 정확성, 진실성 등에 대하여 보증하지 않습니다.",
        },
      ],
    },
    {
      title: "제10조 (서비스의 요금)",
      content: [
        {
          text: "① 인증 회원의 가입과 거래정보 제공은 원칙적으로 무료입니다.",
        },
      ],
    },
    {
      title: "제11조 (서비스 이용시간 및 제공 중지)",
      content: [
        {
          text: "① 회사는 특별한 사유가 없는 한 연중무휴, 1일 24시간 서비스를 제공합니다.",
        },
        {
          text: "② 회사는 서비스 개선을 위한 시스템 업데이트 기타 유지보수 작업 등을 진행하고자 하는 경우, 사전에 서비스 중단 시간과 작업 내용을 고지하여 일시적 서비스 중단을 시행할 수 있습니다.",
        },
        {
          text: "③ 회사의 성실한 의무 이행에도 불구하고 다음 각 호의 사유로 서비스 중지 등 회원의 손해가 발생한 경우, 회사는 그로 인한 책임을 부담하지 않습니다.",
          detail: [
            {
              text: "1. 천재지변 또는 이에 준하는 불가항력으로 인한 손해",
            },
            {
              text: "2. 회원의 귀책으로 발생한 손해",
            },
            {
              text: "3. 제3자의 고의 또는 과실로 발생한 손해",
            },
            {
              text: "4. 기타 회사의 고의 또는 과실 없이 발생한 손해",
            },
          ],
        },
        {
          text: "④ 제2항 내지 제3항에 해당하지 않는 사유로 인하여 인증 회원이 입은 손해는 본 약관 및 법령에 따라 배상합니다.",
        },
      ],
    },
    {
      title: "제12조 (서비스 정보의 제공)",
      content: [
        {
          text: "① 회사는 인증 회원에게 서비스 이용 • 개선을 위해 필요한 사항을 알리기 위해 애플리케이션 푸쉬 알림, 모바일 장치, IoT 등 신종 기술 및 기기를 이용한 방법으로 제공할 수 있습니다.",
        },
        {
          text: "② 회사는 로컬푸드 사업체에서 발송한 푸쉬 알림에 대해 관여하지 않으며 이에 대한 책임을 부담하지 않습니다.",
        },
      ],
    },
    {
      title: "제13조 (회사의 의무)",
      content: [
        {
          text: "① 회사는 본 약관에서 정한 내용에 따라 안정적 • 계속적으로 서비스를 제공할 수 있도록 최선을 다하여 노력합니다.",
        },
        {
          text: "② 서비스의 이용이나 운영과 관련된 인증 회원의 불만사항이 접수되는 경우, 회사는 이를 지체없이 처리하여 그 결과를 회신할 수 있도록 노력합니다. 다만, 불만사항 내용 확인 및 경위 파악, 접수 내용 처리 등에 상당한 시간이 소요될 경우, 회사는 그 사유와 처리일정을 인증 회원에게 통지합니다.",
        },
        {
          text: "③ 회사는 천재지변 등 예측하지 못한 일이 발생하거나 시스템의 장애가 발생하여 서비스가 중단될 경우 이에 대한 손해에 대해서는 책임을 지지 않습니다. 다만 자료의 복구나 정상적인 서비스 지원이 되도록 최선을 다할 의무를 부담합니다.",
        },
      ],
    },
    {
      title: "제14조 (인증회원의 의무)",
      content: [
        {
          text: "① 인증 회원은 관계법령과 본 약관의 규정, 회사의 서비스 운영정책 기타 고지된 서비스 이용상의 유의 사항을 준수하여야 하며, 기타 회사의 업무에 지장을 초래하는 행위를 하여서는 아니됩니다.",
        },
        {
          text: "② 인증 회원은 서비스를 이용하여 얻은 정보를 회사의 사전동의 없이 복사, 복제, 번역, 출판, 방송 기타의 방법으로 사용하거나 이를 타인에게 제공할 수 없습니다.",
        },
        {
          text: "③ 인증 회원의 비밀번호 등의 관리책임은 인증 회원에게 있습니다. 단, 회사측 서비스의 결함에 따른 정보유실의 발생에 대한 책임은 인증 회원이 부담하지 않습니다.",
        },
        {
          text: "④ 인증 회원은 서비스를 이용하여 얻은 정보를 회사의 사전동의 없이 복사, 복제, 번역, 출판, 방송 기타의 방법으로 사용하거나 이를 타인에게 제공할 수 없습니다.",
        },
        {
          text: "⑤ 인증 회원은 다음 각 호의 행위를 하여서는 안 됩니다.",
          detail: [
            {
              text: "1. 다른 회원의 아이디를 부정 사용하는 행위",
            },
            {
              text: "2. 범죄행위를 목적으로 하거나 기타 범죄행위와 관련된 행위",
            },
            {
              text: "3. 지적재산권 등의 권리를 침해하는 행위",
            },
            {
              text: "4. 서비스의 안정적인 운영에 지장을 주거나 줄 우려가 있다고 판단되는 행위",
            },
            {
              text: "5. 사이트의 정보 및 서비스를 이용한 영리 행위 ",
            },
          ],
        },
      ],
    },
    {
      title: "제15조 (서비스 이용계약 해지/서비스중지/자료삭제)",
      content: [
        {
          text: '① 인증 회원이 서비스 이용계약을 해지하고자 할 경우, "회원 탈퇴" 메뉴를 이용해 회사에 대한 해지 신청을 합니다. 회사는 즉시 가입해지(회원탈퇴)에 필요한 조치를 취합니다.',
        },
        {
          text: "② 인증회원이 서비스 이용계약을 해지한 경우, 회사는 해지 즉시 인증 회원의 모든 정보를 파기합니다. 다만, 로컬푸드 사업체가 수집한 정보는 회사에서 관여하지 않습니다.",
        },
        {
          text: "③ 다음의 사항에 해당하는 경우 회사는 인증 회원의 사전 동의를 얻지 않고 서비스 이용 제한, 인증 회원의 자격박탈 등의 조치(이하 “회사의 제재조치”)를 취할 수 있습니다.",
          detail: [
            {
              text: "1. 유료서비스 이용 요금을 납부하지 않았을 때",
            },
            {
              text: "2. 제7조 제2항의 각 호 중 어느 하나에 해당하는 행위를 하였을 때",
            },
            { text: "3. 본 서비스에서 제공되는 정보를 유용하였을 때" },
            {
              text: "4. 관련 법규에 위반하거나 선량한 풍속 기타 사회통념상 허용되지 않는 행위를 하였을 때",
            },
            {
              text: "5. 기타 본 서비스의 명예를 훼손하였거나 회사가 판단하기에 적합하지 않은 목적으로 회원가입을 하였을 때",
            },
          ],
        },
        {
          text: "④ 회원은 제3항에 따른 회사의 제재조치에 대하여 이의 사유 및 증빙자료를 제출하는 방식으로 회사에게 이의를 신청할 수 있습니다. 회원의 이의 신청을 접수한 경우, 회사는 제출된 이의 사유 및 증빙자료 기타 관련 사항을 신중히 검토하여 이의 신청에 대한 판단을 하고 그 결과를 통지합니다. 이에 대한 상세한 내용은 개인정보 처리방침을 확인 바랍니다.",
        },
        {
          text: "⑤ 인증 회원이 유료서비스를 이용하는 중 회사의 책임에 의해 정상적인 서비스가 제공되지 않을 경우 회원은 본 서비스의 해지를 요청할 수 있으며 회사는 해지일까지 이용일수를 1일 기준금액으로 계산하여 이용금액을 공제후 환급합니다.",
        },
      ],
    },
    {
      title: "제16조 (휴면계정)",
      content: [
        {
          text: "인증 회원이 선택한 개인정보 보유기간 동안 로그인을 하지 않은 경우 해당 인증 회원의 계정은 파기처리됩니다.",
        },
      ],
    },
    {
      title: "제17조 (손해배상 및 면책)",
      content: [
        {
          text: "① 회사가 본 약관의 제 9조, 제 13조 등의 규정에 위반한 행위로 읹으 회원에게 손해를 입히거나 기타 회사가 제공하는 모든 서비스와 관련하여 회사의 책임 있는 사유로 인해 인증 회원에게 손해가 발생한 경우, 회사는 그 손해를 배상하여야 합니다.",
        },
        {
          text: "② 인증 회원이 본 약관의 제 7조, 제 14조 등의 규정에 위반한 행위로 회사 및 제3자에게 손해를 입히거나 인증 회원의 책임 있는 사유에 의해 회사 및 제3자에게 손해를 입힌 경우에는 인증 회원은 그 손해를 배상하여야 합니다.",
        },
        {
          text: "③ 회사는 인증 회원의 본 서비스를 통해 제공되는 정보에 대해 어떠한 책임도 부담하지 않습니다. 또한, 회사는 회사가 무료로 제공하는 서비스의 이용과 관련하여 개인정보 처리방침에서 정하는 내용에 위반하지 않은 한 이용자에게 어떠한 손해도 책임지지 않습니다.",
        },
        {
          text: "④ 회사는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우 서비스 제공에 관한 책임이 면제됩니다.",
        },
        {
          text: "⑤ 회사는 서비스 이용과 관련하여 인증 회원에게 발생한 손해 가운데 개인회원의 고의 또는 과실로 인한 서비스 이용의 장애 및 손해에 대하여 어떠한 책임도 부담하지 않습니다.",
        },
      ],
    },
    {
      title: "제18조 (회원의 개인정보보호)",
      content: [
        {
          text: "회사는 이용자의 개인정보를 보호하기 위하여 노력합니다. 회사는 정보통신망 이용 촉진 및 정보 보호 등에 관한 법률, 개인정보 보호법을 준수하고, 개인정보 보호를 위하여 사이트에 개인정보 처리방침을 고지합니다.",
        },
      ],
    },
    {
      title: "제19조 (분쟁의 해결)",
      content: [
        {
          text: "① 회사와 인증 회원은 서비스와 관련하여 발생한 분쟁을 원만하게 해결하기 위하여 필요한 모든 노력을 하여야 합니다.",
        },
        {
          text: "② 전항의 노력에도 불구하고, 회사와 인증 회원 간에 발생한 분쟁에 관한 소송이 제기될 경우, 민사소송법에 따른 관할법원을 제1심 관할법원으로 지정합니다.",
        },
      ],
    },
  ],
  customerApp: [
    {
      bigTitle: "개인정보 수집 및 이용에 대한 안내",
      title: "",
    },
    {
      title: "개인정보의 수집 범위",
      content: [
        {
          text: "회사는 가입의사 확인 및 서비스 제공을 위해 아래와 같이 개인정보를 수집할 수 있습니다. 하기 목적이외의 용도로는 사용되지 않습니다.",
          table: {
            title: "수집방법|수집정보|목적",
            titleLength: 3,
            contents: [
              {
                text: "회원가입|(필수) 아이디, 비밀번호|가입의사 확인, 서비스 제공",
              },
              {
                text: "회원가입|(필수) 휴대전화 번호|이용자 식별",
              },
            ],
          },
          list: [
            {
              title: "수집방법",
              content: [
                {
                  text: "회원가입",
                },
              ],
            },
            {
              title: "수집정보",
              content: [
                {
                  text: "(필수) 아이디, 비밀번호",
                },
                {
                  text: "(필수) 휴대전화 번호",
                },
              ],
            },
            {
              title: "목적",
              content: [
                {
                  text: "가입의사 확인, 서비스 제공",
                },
                {
                  text: "이용자 식별",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      title: "개인정보의 보유 및 이용기간",
      content: [
        {
          text: "회사는 이용자의 개인정보를 고지 및 동의 받은 사항에 따라 수집∙이용 목적이 달성되기 전 또는 이용자의 탈퇴 요청이 있기 전까지 해당 정보를 보유합니다. 다만, 아래의 사유로 인하여 보관이 필요한 경우 외부와 차단된 별도 DB 또는 테이블에 분리 보관 됩니다.",
          detail: [
            {
              text: "가. 온라인/모바일 서비스 제공을 위해 수집한 회원가입 정보 : 회원탈퇴 시까지",
            },
          ],
        },
      ],
    },
    {
      title: "동의 거부 권리 및 불이익 안내",
      content: [
        {
          text: "본 안내에 따른 개인정보 수집, 이용에 대하여 동의를 거부하실 권리가 있습니다.",
        },
        {
          text: "다만, 개인정보의 수집/이용에 동의를 거부한 경우 서비스 이용에 있어 불이익이 발생할 수 있음을 알려드립니다.",
        },
      ],
    },
  ],
  fammerApp: [
    {
      bigTitle: "개인정보 수집 및 이용에 대한 안내",
      title: "",
    },
    {
      title: "개인정보의 수집 범위",
      content: [
        {
          text: "회사는 가입의사 확인 및 서비스 제공을 위해 아래와 같이 개인정보를 수집할 수 있습니다. 하기 목적이외의 용도로는 사용되지 않습니다.",
          table: {
            title: "수집방법|수집정보|목적",
            titleLength: 3,
            contents: [
              {
                text: "회원가입|(필수) 아이디, 비밀번호|가입의사 확인, 서비스 제공",
              },
              {
                text: "회원가입|(필수) 휴대전화 번호|이용자 식별",
              },
            ],
          },
          list: [
            {
              title: "수집방법",
              content: [
                {
                  text: "회원가입",
                },
              ],
            },
            {
              title: "수집정보",
              content: [
                {
                  text: "(필수) 아이디, 비밀번호",
                },
                {
                  text: "(필수) 휴대전화 번호",
                },
              ],
            },
            {
              title: "목적",
              content: [
                {
                  text: "가입의사 확인, 서비스 제공",
                },
                {
                  text: "이용자 식별",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      title: "개인정보의 보유 및 이용기간",
      content: [
        {
          text: "회사는 이용자의 개인정보를 고지 및 동의 받은 사항에 따라 수집∙이용 목적이 달성되기 전 또는 이용자의 탈퇴 요청이 있기 전까지 해당 정보를 보유합니다. 다만, 아래의 사유로 인하여 보관이 필요한 경우 외부와 차단된 별도 DB 또는 테이블에 분리 보관 됩니다.",
          detail: [
            {
              text: "가. 온라인/모바일 서비스 제공을 위해 수집한 회원가입 정보 : 회원탈퇴 시까지",
            },
          ],
        },
      ],
    },
    {
      title: "동의 거부 권리 및 불이익 안내",
      content: [
        {
          text: "본 안내에 따른 개인정보 수집, 이용에 대하여 동의를 거부하실 권리가 있습니다.",
        },
        {
          text: "다만, 개인정보의 수집/이용에 동의를 거부한 경우 서비스 이용에 있어 불이익이 발생할 수 있음을 알려드립니다.",
        },
      ],
    },
  ],
  secession: [
    {
      bigTitle: "회원탈퇴안내",
      title: "",
      content: [
        {
          text: "회월탈퇴 신청 전 안내 사항을 숙지하시기 바랍니다.",
          detail: [
            {
              text: "1. 회원 탈퇴를 하시면 해당 아이디는 즉시 탈퇴처리됩니다.",
            },
            {
              text: "2. 회원 탈퇴시 동일 아이디로 즉시 재가입이 가능합니다.",
            },
            {
              text: "• 상세한 내용은 개인정보 취급방침을 참고 해 주세요.",
            },
          ],
        },
      ],
    },
  ],
};
